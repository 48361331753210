<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Reset Password v1 -->
        <b-card class="mb-0">

          <!-- logo -->
          <b-link class="brand-logo">
            <img src="/favicon.png">
            <h2 class="brand-text text-primary ml-1">
              Pitjarus Teknologi
            </h2>
          </b-link>

          <b-card-title class="mb-1">
            Reset Password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Your new password must be different from previously used passwords
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              method="POST"
              class="auth-reset-password-form mt-2"
              @submit.prevent="validationForm"
            >

              <!-- password -->
              <b-form-group
                label="New Password"
                label-for="reset-password-new"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-new"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="reset-password-new"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false:null"
                      name="reset-password-confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                variant="primary"
              >
                Set New Password
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> Back to login
            </b-link>
          </p>

        </b-card>
        <!-- /Reset Password v1 -->
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BOverlay,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { makeToast } from '@/@fake-db/utils'

export default {
  components: {
    VuexyLogo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BOverlay,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      showLoading: false,
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    this.showLoading = true
    useJwt.jwtPost('/auth/validate-code', { code: this.$route.params.code }).then(() => {
      this.showLoading = false
    }).catch(e => {
      this.showLoading = false
      const message = e.response ? e.response.data.error ?? e.message : e.message
      this.$router.push({ name: 'auth-login' })
        .then(() => {
          makeToast(this, message, 'AlertTriangleIcon', 'danger')
        })
    })
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          console.log(this.$route.params.code)
          useJwt.jwtPost('/auth/change-password', { code: this.$route.params.code, password: this.password, cPassword: this.cPassword }).then(() => {
            this.showLoading = false
            this.$router.push({ name: 'auth-login' })
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Password changed successfully',
                    icon: 'EditIcon',
                    variant: 'success',
                  },
                })
              })
          }).catch(e => {
            this.showLoading = false
            const message = e.response ? e.response.data.error ?? e.message : e.message
            makeToast(this, message, 'AlertTriangleIcon', 'danger')
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
